import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllPages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/cms/libraryitems',{ params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/cms/librarycategories',{ params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
    deleteItem(ctx, queryParams) {
      var id=queryParams.id;
      queryParams.deleted=true;

console.log("DEleting Item");
console.log(queryParams);

      return new Promise((resolve, reject) => {
        axios
          .put(`admin/cms/updatelibraryitem`,  queryParams  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchPost(ctx, queryParams) {
      var id=queryParams.id;

      return new Promise((resolve, reject) => {
        axios
          .get(`admin/cms/blogpost/${id}`,{ params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    


  },
}
